import {Loading} from '@components/atoms';
import {useAppContextHelper} from '@hooks/_contexts';
import {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from "@redux";
import {
    proveCampaignIsError,
    proveCampaignIsPending,
    proveSelectedCampaign
} from "@redux/slices/prove/campaign/selectors";
import {ErrorCard} from "@components/molecules/errorCard/ErrorCard";
import {proveAdFormatIsError} from "@redux/slices/prove/adFormat/selectors";
import {
    proveAvailableLineItems,
    proveLineItemIsError,
    proveLineItemIsPending
} from "@redux/slices/prove/lineItem/selectors";
import {ProveLineItem} from "@api-clients/prove/schema/ProveLineItem";
import {proveDashboardBreakdownIsError, proveDashboardOverviewIsError} from "@redux/slices/prove/dashboard/selectors";
import {
    ProveDashboardHeader,
    ProveDashboardSearchCriteriaProps
} from "@components/organisms/prove/ProveDashboardHeader";
import {ProveDashboardBody} from "@components/organisms/prove/ProveDashboardBody";
import {selectedGlobalCampaign} from "@redux/slices/global/campaign/selectors";

export const ProveDashboardPage: FC = () => {
    const {campaignId} = useParams();
    const selectedGlobalCampaignSelector = useSelector(selectedGlobalCampaign);
    const proveSelectedCampaignSelector = useSelector(proveSelectedCampaign);
    const proveCampaignIsPendingSelector = useSelector(proveCampaignIsPending);
    const proveCampaignIsErrorSelector = useSelector(proveCampaignIsError);
    const adFormatIsErrorSelector = useSelector(proveAdFormatIsError);
    const dashboardOverviewIsErrorSelector = useSelector(proveDashboardOverviewIsError);
    const dashboardBreakdownIsErrorSelector = useSelector(proveDashboardBreakdownIsError);
    const {
        currentContextValues: {organisation, user}
    } = useAppContextHelper();
    const [dashboardSearchCriteria, setDashboardSearchCriteria] = useState(undefined as ProveDashboardSearchCriteriaProps | undefined);
    const availableLineItemsSelector = useSelector(proveAvailableLineItems);
    const lineItemIsPendingSelector = useSelector(proveLineItemIsPending);
    const lineItemIsErrorSelector = useSelector(proveLineItemIsError);
    const [lineItems, setLineItems] = useState([] as Array<ProveLineItem>);
    const {
        helper: {
            selectGlobalCampaign,
            selectProveCampaign,
            retrieveProveLineItems,
        },
    } = useAppContextHelper();

    useEffect(() => {
        if (!campaignId) {
            return;
        }

        selectGlobalCampaign(campaignId).catch();
        selectProveCampaign(campaignId).catch();
    }, [campaignId]);

    useEffect(() => {
        if (!proveSelectedCampaignSelector) {
            return;
        }

        retrieveProveLineItems(proveSelectedCampaignSelector).catch();
    }, [proveSelectedCampaignSelector]);

    useEffect(() => {
        if (!availableLineItemsSelector) {
            return;
        }

        if (lineItems.length === 0 && availableLineItemsSelector.length > 0) {
            setLineItems(availableLineItemsSelector);
        }
    }, [availableLineItemsSelector]);

    return !proveCampaignIsErrorSelector && !adFormatIsErrorSelector && !lineItemIsErrorSelector && !dashboardOverviewIsErrorSelector && !dashboardBreakdownIsErrorSelector && organisation?.users ? (
        <>
            {!proveCampaignIsPendingSelector && !lineItemIsPendingSelector && proveSelectedCampaignSelector && selectedGlobalCampaignSelector && availableLineItemsSelector ? (
                <>
                    <ProveDashboardHeader globalCampaign={selectedGlobalCampaignSelector}
                                          proveCampaign={proveSelectedCampaignSelector}
                                          lineItems={availableLineItemsSelector}
                                          setDashboardSearchCriteria={setDashboardSearchCriteria}/>
                    {dashboardSearchCriteria && (
                        <>
                            <ProveDashboardBody globalCampaign={selectedGlobalCampaignSelector}
                                                proveCampaign={proveSelectedCampaignSelector}
                                                searchCriteria={dashboardSearchCriteria}/>
                        </>
                    )}
                </>
            ) : (
                <Loading/>
            )}
        </>
    ) : (
        <ErrorCard/>
    );
};
