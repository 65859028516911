import React, { FC, useEffect, useRef, useState } from 'react';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Heading,
    HStack,
    Spacer,
    Stack,
    Tab,
    TabList,
    Tabs,
    VStack,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from '@redux';
import { useApiConfiguration } from '@hooks/configuration';
import { ProveCampaign } from '@api-clients/prove/schema/ProveCampaign';
import { ProveDashboardSearchCriteriaProps } from '@components/organisms/prove/ProveDashboardHeader';
import {
    retrieveProveBreakdownReportDataAsync,
    retrieveProveOverviewReportDataAsync,
} from '@redux/slices/prove/dashboard/thunks';
import { AsyncCard } from '@components/atoms';
import { proveAdFormatIsPending, proveAvailableAdFormats } from '@redux/slices/prove/adFormat/selectors';
import { findProveAdFormatsAsync } from '@redux/slices/prove/adFormat/thunks';
import {
    proveDashboardBreakdownData,
    proveDashboardBreakdownIsPending,
    proveDashboardOverviewData,
    proveDashboardOverviewIsPending,
} from '@redux/slices/prove/dashboard/selectors';
import { useTranslation } from 'react-i18next';
import { ProveDashboardChart } from '@components/organisms/prove/ProveDashboardChart';
import { ProveDashboardTable } from '@components/organisms/prove/ProveDashboardTable';
import { ChartBarIcon, TableCellsIcon } from '@heroicons/react/24/solid';
import { DownloadIcon } from '@chakra-ui/icons';
import { ProveDashboardHighlights } from '@components/organisms/prove/ProveDashboardHighlights';
import { ProveDashboardNoData } from '@components/organisms/prove/ProveDashboardNoData';
import { GlobalCampaign } from '@api-clients/global/campaign/schema/GlobalCampaign';
import { ProveFlattenedReportBreakdownEntry } from '@api-clients/prove/schema/ProveDashboard';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

interface ProveDashboardBreakdownField {
    id: string;
    v1: string;
    v2: string;
    label: string;
    maxRecords?: number;
}

export interface ProveDashboardBreakdownConfig {
    id: string;
    label: string;
    fields: Array<ProveDashboardBreakdownField>;
    chartType: string;
    chartLimit?: number;
}

export interface ProveDashboardFocusConfig {
    id: string;
    label: string;
}

export interface ProveDashboardBreakdownProps {
    globalCampaign: GlobalCampaign;
    proveCampaign: ProveCampaign;
    searchCriteria: ProveDashboardSearchCriteriaProps;
}

export const ProveDashboardBreakdown: FC<ProveDashboardBreakdownProps> = ({
                                                                              globalCampaign,
                                                                              proveCampaign,
                                                                              searchCriteria,
                                                                          }) => {

    const dispatch = useDispatch();
    const { getProveManagementServiceConfig, getProveReportServiceConfig } = useApiConfiguration();
    const adFormatIsPendingSelector = useSelector(proveAdFormatIsPending);
    const availableAdFormatsSelector = useSelector(proveAvailableAdFormats);
    const dashboardOverviewIsPendingSelector = useSelector(proveDashboardOverviewIsPending);
    const dashboardOverviewDataSelector = useSelector(proveDashboardOverviewData);
    const dashboardBreakdownIsPendingSelector = useSelector(proveDashboardBreakdownIsPending);
    const dashboardBreakdownDataSelector = useSelector(proveDashboardBreakdownData);
    const [breakdownTabIndex, setBreakdownTabIndex] = useState(0);
    const [viewTabIndex, setViewTabIndex] = useState(0);
    const [breakdownData, setBreakdownData] = useState<Array<ProveFlattenedReportBreakdownEntry>>([]);
    const chartRef = useRef<ChartJSOrUndefined>();

    const { t } = useTranslation('prove');
    const content = t('prove.dashboard', {
        returnObjects: true,
    });

    const deviceBreakdownField: ProveDashboardBreakdownField = {
        id: 'deviceType',
        v1: 'deviceType',
        v2: 'device.type',
        label: `${content.breakdown.labels.device}`,
    };

    const publisherBreakdownField: ProveDashboardBreakdownField = {
        id: 'publisherId',
        v1: 'publisherId',
        v2: 'publisher.id',
        label: `${content.breakdown.labels.publisher}`,
        maxRecords: 100,
    };

    const adFormatBreakdownField: ProveDashboardBreakdownField = {
        id: 'adFormat',
        v1: 'adFormat',
        v2: 'advert.format',
        label: `${content.breakdown.labels.adFormat}`,
    };

    const lineItemBreakdownField: ProveDashboardBreakdownField = {
        id: 'lineItem',
        v1: 'lineItem',
        v2: 'metadata.lineItemId',
        label: `${content.breakdown.labels.lineItem}`,
    };

    const availableBreakdownConfig = [
        {
            id: 'highlights',
            label: content.tabs.highlights,
            fields: [deviceBreakdownField, publisherBreakdownField, adFormatBreakdownField],
        },
        {
            id: 'hierarchy',
            label: content.tabs.hierarchy,
            fields: [deviceBreakdownField, publisherBreakdownField, adFormatBreakdownField],
            chartType: 'bubble',
            chartLimit: 50,
        },
        {
            id: 'device',
            label: content.tabs.device,
            fields: [deviceBreakdownField],
            chartType: 'bar',
        },
        {
            id: 'publisher',
            label: content.tabs.publisher,
            fields: [publisherBreakdownField],
            chartType: 'bar',
            chartLimit: 10,
        },
        {
            id: 'adFormat',
            label: content.tabs.adFormat,
            fields: [adFormatBreakdownField],
            chartType: 'bar',
        },
        {
            id: 'lineItem',
            label: content.tabs.lineItem,
            fields: [lineItemBreakdownField],
            chartType: 'bar',
        },
    ] as Array<ProveDashboardBreakdownConfig>;

    const [selectedBreakdownConfig, setSelectedBreakdownConfig] = useState(availableBreakdownConfig[0]);

    const availableBreakdownFocusConfig = [
        {
            id: 'all',
            label: content.breakdown.titles.all,
        },
        {
            id: 'bestPerforming',
            label: content.breakdown.titles.bestPerforming,
        },
        {
            id: 'lowPerforming',
            label: content.breakdown.titles.lowPerforming,
        },
        {
            id: 'highActive',
            label: content.breakdown.titles.highActive,
        },
        {
            id: 'highPassive',
            label: content.breakdown.titles.highPassive,
        },
    ] as Array<ProveDashboardFocusConfig>;

    const [breakdownDataFocus, setBreakdownDataFocus] = useState(availableBreakdownFocusConfig[0]);

    useEffect(() => {
        if (availableAdFormatsSelector) {
            return;
        }

        const retrieveAdFormats = async () => {
            dispatch(findProveAdFormatsAsync({
                configuration: await getProveManagementServiceConfig(),
                isDemoCampaign: globalCampaign.id === 'demo',
            }));
        };

        retrieveAdFormats().catch();
    }, []);

    useEffect(() => {
        const retrieveBreakdownReportData = async () => {
            dispatch(retrieveProveBreakdownReportDataAsync({
                configuration: await getProveReportServiceConfig(),
                campaign: proveCampaign!,
                lineItems: searchCriteria.selectedLineItems,
                breakdown: selectedBreakdownConfig.fields.map(field => {
                    const targetFieldValue = (proveCampaign!.apiVersion === "V2") ? field.v2 : field.v1;

                    if (field.maxRecords) {
                        return `${targetFieldValue}:${field.maxRecords}`;
                    }

                    return targetFieldValue;
                }),
                start: searchCriteria.startDate,
                end: searchCriteria.endDate,
            }));
        };

        retrieveBreakdownReportData().catch();
    }, [searchCriteria, selectedBreakdownConfig]);

    useEffect(() => {
        if (!dashboardOverviewDataSelector || !dashboardBreakdownDataSelector) {
            return;
        }

        if (breakdownDataFocus.id === 'all') {
            setBreakdownData(dashboardBreakdownDataSelector);
        }

        if (breakdownDataFocus.id === 'lowPerforming') {
            setBreakdownData(dashboardBreakdownDataSelector.filter((entry) => {
                return entry.metrics.avgActiveAttention <= dashboardOverviewDataSelector.avgActiveAttention &&
                    entry.metrics.avgPassiveAttention <= dashboardOverviewDataSelector.avgPassiveAttention;
            }));
        }

        if (breakdownDataFocus.id === 'bestPerforming') {
            setBreakdownData(dashboardBreakdownDataSelector.filter((entry) => {
                return entry.metrics.avgActiveAttention >= dashboardOverviewDataSelector.avgActiveAttention &&
                    entry.metrics.avgPassiveAttention >= dashboardOverviewDataSelector.avgPassiveAttention;
            }));
        }

        if (breakdownDataFocus.id === 'highActive') {
            setBreakdownData(dashboardBreakdownDataSelector.filter((entry) => {
                return entry.metrics.avgActiveAttention >= dashboardOverviewDataSelector.avgActiveAttention;
            }));
        }

        if (breakdownDataFocus.id === 'highPassive') {
            setBreakdownData(dashboardBreakdownDataSelector.filter((entry) => {
                return entry.metrics.avgPassiveAttention >= dashboardOverviewDataSelector.avgPassiveAttention;
            }));
        }
    }, [dashboardOverviewDataSelector, dashboardBreakdownDataSelector, breakdownDataFocus]);

    const resolveDeviceType = (value: string) => {
        switch (value) {
            case 'MOBILE':
                return 'Mobile';
            case 'TABLET':
                return 'Tablet';
            case 'DESKTOP':
                return 'Desktop';
            case 'CONNECTED_TV':
                return 'Connected TV';
            case 'OTHER':
                return 'Other';
            default:
                return value;
        }
    };

    const resolveAdFormatName = (value: string) => {
        for (let i = 0; i < availableAdFormatsSelector!.length; i++) {
            const adFormat = availableAdFormatsSelector![i];

            if (adFormat.code === value) {
                return adFormat.name;
            }
        }

        return value;
    };

    const resolveLineItemName = (value: string) => {
        for (let i = 0; i < searchCriteria.selectedLineItems.length; i++) {
            const lineItem = searchCriteria.selectedLineItems[i];

            if (lineItem.externalId === value || lineItem.partnerId === value) {
                return lineItem.name;
            }
        }

        return value;
    };

    const valueFormatter = (type: string, value: string) => {
        switch (type) {
            case 'lineItem':
                return resolveLineItemName(value);
            case 'adFormat':
                return resolveAdFormatName(value);
            case 'deviceType':
                return resolveDeviceType(value);
            default:
                return value;
        }
    };

    useEffect(() => {
        if (!searchCriteria) {
            return;
        }

        const retrieveOverviewReportData = async () => {
            dispatch(retrieveProveOverviewReportDataAsync({
                configuration: await getProveReportServiceConfig(),
                campaign: proveCampaign!,
                lineItems: searchCriteria.selectedLineItems,
                start: searchCriteria.startDate,
                end: searchCriteria.endDate,
            }));
        };

        retrieveOverviewReportData().catch();
    }, [searchCriteria]);

    function getBase64Image() {
        if (chartRef?.current) {
            return chartRef.current.toBase64Image();
        }

        return undefined;
    }

    function getCsvContent() {
        let csvContent = 'data:text/tsv;charset=utf-8,';

        csvContent += `${selectedBreakdownConfig.fields.map((field) => field.label).join('\t')}\t${content.breakdown.labels.impressions}\t${content.breakdown.labels.averageActiveAttention}\t${content.breakdown.labels.averagePassiveAttention}\r\n`;
        const csvFormattedData = breakdownData.map((data) => {
            const formattedDataValues = selectedBreakdownConfig.fields.map((field, index) => {
                return valueFormatter(field.v1, data.value[index]);
            });

            return `${formattedDataValues.join('\t')}\t${data.metrics.impressions}\t${data.metrics.avgActiveAttention}\t${data.metrics.avgPassiveAttention}\r\n`;
        });

        for (let i = 0; i < csvFormattedData.length; i++) {
            csvContent += csvFormattedData[i];
        }

        return encodeURI(csvContent);
    }

    function download() {
        const isChartImage = (viewTabIndex === 0);
        const href = isChartImage ? getBase64Image() : getCsvContent();
        const filename = `${globalCampaign.name} - ${selectedBreakdownConfig.label} report - ${breakdownDataFocus.label} - ${searchCriteria.startDate.toLocaleDateString()} ${searchCriteria.endDate.toLocaleDateString()}.${(isChartImage) ? 'png' : 'tsv'}`;

        const link = document.createElement('a');
        link.download = filename;
        link.href = href!;
        link.click();
    }

    return (
        <>
            <Tabs
                isFitted
                tabIndex={breakdownTabIndex}
                defaultIndex={breakdownTabIndex}
                onChange={(index) => {
                    setBreakdownTabIndex(index);
                    setSelectedBreakdownConfig(availableBreakdownConfig[index]);
                }}
            >
                <TabList>
                    {availableBreakdownConfig.map((entry) => {
                        return (<Tab
                            key={entry.id}
                            display="flex"
                            flexDirection="column"
                            paddingY="1.5rem"
                        >
                            <Heading
                                fontSize="0.75rem"
                                color="green.500"
                                lineHeight="1rem"
                                alignSelf="stretch"
                            >
                                {entry.label}
                            </Heading>
                        </Tab>);
                    })}
                </TabList>
            </Tabs>
            <AsyncCard
                mt={4}
                isLoading={adFormatIsPendingSelector || dashboardOverviewIsPendingSelector || dashboardBreakdownIsPendingSelector}>
                <Box>
                    {availableAdFormatsSelector && dashboardOverviewDataSelector && breakdownData && breakdownData.length > 0 ? (
                        <>
                            {selectedBreakdownConfig.id !== 'highlights' && (
                                <Tabs tabIndex={viewTabIndex} defaultIndex={viewTabIndex}
                                      onChange={setViewTabIndex}>
                                    <Stack direction="row">
                                        <HStack spacing={2}>
                                            {availableBreakdownFocusConfig.map((entry) => {
                                                return (<Button
                                                    variant={breakdownDataFocus.id === entry.id ? 'solid' : 'outline'}
                                                    size="sm"
                                                    colorScheme="green"
                                                    rounded="1.0rem"
                                                    onClick={() => {
                                                        setBreakdownDataFocus(entry);
                                                    }}
                                                >{entry.label}</Button>);
                                            })}
                                        </HStack>
                                        <Spacer />
                                        <TabList mr={6}>
                                            <Tab>
                                                <ChartBarIcon width={18} color="gray" />
                                            </Tab>
                                            <Tab>
                                                <TableCellsIcon width={24} color="gray" />
                                            </Tab>
                                        </TabList>
                                        <Button
                                            rightIcon={<DownloadIcon />}
                                            colorScheme="orange"
                                            onClick={() => {
                                                download();
                                            }}>
                                            {content.buttons.download}
                                        </Button>
                                    </Stack>
                                    {breakdownDataFocus.id !== 'all' && (
                                        <Alert mt={4}
                                               status="info"
                                               variant="left-accent"
                                               rounded="0.3rem"
                                               alignItems="left"
                                               colorScheme="green">
                                            <AlertIcon />
                                            <VStack alignItems="left">
                                                {breakdownDataFocus.id === 'bestPerforming' && (
                                                    <AlertDescription>{content.breakdown.infoBanner.bestPerforming}</AlertDescription>
                                                )}
                                                {breakdownDataFocus.id === 'lowPerforming' && (
                                                    <AlertDescription>{content.breakdown.infoBanner.lowPerforming}</AlertDescription>
                                                )}
                                                {breakdownDataFocus.id === 'highActive' && (
                                                    <AlertDescription>{content.breakdown.infoBanner.highActive}</AlertDescription>
                                                )}
                                                {breakdownDataFocus.id === 'highPassive' && (
                                                    <AlertDescription>{content.breakdown.infoBanner.highPassive}</AlertDescription>
                                                )}
                                            </VStack>
                                        </Alert>
                                    )}
                                </Tabs>
                            )}
                            <Box m={4}>
                                {selectedBreakdownConfig.id === 'highlights' ? (
                                    <ProveDashboardHighlights breakdownConfig={selectedBreakdownConfig}
                                                              valueFormatter={valueFormatter} />
                                ) : (
                                    <>
                                        {viewTabIndex === 0 ? (
                                            <ProveDashboardChart breakdownConfig={selectedBreakdownConfig}
                                                                 valueFormatter={valueFormatter}
                                                                 chartRef={chartRef}
                                                                 breakdownData={breakdownData}
                                                                 showAnnotations={breakdownDataFocus.id === 'all'} />
                                        ) : (
                                            <ProveDashboardTable breakdownConfig={selectedBreakdownConfig}
                                                                 valueFormatter={valueFormatter}
                                                                 breakdownData={breakdownData} />
                                        )}
                                    </>
                                )}
                            </Box>
                        </>
                    ) : (
                        <ProveDashboardNoData />
                    )}
                </Box>
            </AsyncCard>
        </>
    );

};
