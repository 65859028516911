import { UserWithAccessLevelDto } from '@api-clients/account-manager';
import {
    Badge,
    Box,
    Button,
    Center,
    Heading,
    HStack,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    Skeleton,
    Text,
    Tooltip,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { Column, DataTable, DynamicUpgradeModal, Row } from '@components/molecules';
import { routing } from '@configs';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ChartBarSquareIcon,
    EllipsisVerticalIcon,
    PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { GlobalCampaign } from '@api-clients/global/campaign/schema/GlobalCampaign';
import { useDispatch, useSelector } from '@redux';
import { useApiConfiguration } from '@hooks/configuration';
import {
    createProveCampaignAsync,
    findAllProveCampaignsAsync,
} from '@redux/slices/prove/campaign/thunks';
import {
    proveAllCampaigns,
    proveCampaignIsPending,
    proveSelectedCampaign,
} from '@redux/slices/prove/campaign/selectors';
import { useCreateDraftCampaign } from '@hooks/campaigns';
import {
    CampaignBasicInfoResponseDto,
    CampaignStatus,
    CreateDraftCampaignRequestDto,
} from '@api-clients/media-plan';
import { AsyncCard } from '@components/atoms';
import { NavBarMenuListItem } from '@components/atoms/navBarItem';
import { NavBarMenuListItemProps } from '@components/atoms/navBarItem/NavBarMenuListItem';
import { FeatureCode } from '@api-clients/subscriptions';
import { useAppContextHelper } from '@hooks/_contexts';
import { UpgradeMediaPlanLimitHeader } from '@apps/attentionADJUST/components/atoms';
import { EnableProveModal } from '@components/molecules/modals/enableProveModal/EnableProveModal';
import { StrategyReportBasicInfoResponseDto } from '@api-clients/attention-data';
import moment from 'moment/moment';
import { DateFormatter } from '@shared/utils';

export interface CampaignManagementSectionProps {
    campaigns: Array<GlobalCampaign>;
    mediaPlans: Array<CampaignBasicInfoResponseDto>;
    users: Array<UserWithAccessLevelDto>;
    strategyReports: Array<StrategyReportBasicInfoResponseDto>;
    isLoadingStrategyReports: boolean;
}

interface DataRow {
    name: string;
    startDate: Date;
    endDate: Date;
    createdBy: string;
    original: GlobalCampaign;
}
export const GlobalCampaignsTable: FC<CampaignManagementSectionProps> = ({
    campaigns,
    mediaPlans,
    users,
    strategyReports,
    isLoadingStrategyReports,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        currentContextValues: { organisation, error, featureBalances, user },
    } = useAppContextHelper();
    const { getProveManagementServiceConfig } = useApiConfiguration();
    const proveSelectedCampaignSelector = useSelector(proveSelectedCampaign);
    const proveCampaignIsPendingSelector = useSelector(proveCampaignIsPending);
    const proveAllCampaignsSelector = useSelector(proveAllCampaigns);
    const [shouldNavigateToLineItems, setShouldNavigateToLineItems] = useState(false);

    const { createDraftCampaign } = useCreateDraftCampaign();

    const getUserNameFromId = (id?: string | null) => {
        const currentUser = users.find((entry) => entry.id === id);

        return currentUser ? `${currentUser?.firstName} ${currentUser?.lastName}` : 'Unknown';
    };

    const { t: campaignTranslation } = useTranslation('campaign');
    const campaignContent = campaignTranslation('campaign.main', {
        returnObjects: true,
    });

    useEffect(() => {
        if (campaigns.length === 0) {
            return;
        }

        const findAllProveCampaigns = async () => {
            dispatch(
                findAllProveCampaignsAsync({
                    configuration: await getProveManagementServiceConfig(),
                }),
            );
        };

        findAllProveCampaigns().catch();
    }, [campaigns]);

    const campaignActionItems: Array<NavBarMenuListItemProps> = [
        {
            key: 'Edit campaign',
            path: `${routing.campaign.edit.path}`,
            children: 'Edit campaign',
            icon: PencilSquareIcon,
        },
    ];

    const {
        isOpen: isCampaignTooltipOpen,
        onOpen: onCampaignTooltipOpen,
        onClose: onCampaignTooltipClose,
    } = useDisclosure();

    function canManageProveLineItems(campaignId: string) {
        for (let i = 0; i < proveAllCampaignsSelector.length; i++) {
            // at present, the UI only supports line item management for V1 campaigns
            // and campaign line items created and setup for PROVE BYO will be managed by another process.
            if (proveAllCampaignsSelector[i].id === campaignId && proveAllCampaignsSelector[i].apiVersion === "V1") {
                return true;
            }
        }

        return false;
    }

    function isProveEnabled(campaignId: string) {
        for (let i = 0; i < proveAllCampaignsSelector.length; i++) {
            if (proveAllCampaignsSelector[i].id === campaignId) {
                return true;
            }
        }

        return false;
    }

    function isPlanEnabled(campaignId: string) {
        for (let i = 0; i < mediaPlans.length; i++) {
            if (mediaPlans[i].campaignId === campaignId) {
                return true;
            }
        }

        return false;
    }

    function isDraftMediaPlan(campaignId: string) {
        for (let i = 0; i < mediaPlans.length; i++) {
            if (mediaPlans[i].campaignId === campaignId) {
                return mediaPlans[i].campaignStatus === CampaignStatus.Draft;
            }
        }

        return false;
    }

    function hasStrategyReport(campaignId: string): boolean {
        for (let i = 0; i < strategyReports.length; i++) {
            if (strategyReports[i].campaignId === campaignId) {
                return true;
            }
        }
        return false;
    }

    function resolveMediaPlanId(campaignId: string) {
        for (let i = 0; i < mediaPlans.length; i++) {
            if (mediaPlans[i].campaignId === campaignId) {
                return mediaPlans[i].id;
            }
        }

        throw Error('The supplied global campaign id is not connected to any media plans');
    }
    function resolvedStrategyPlanId(campaignId: string) {
        for (let i = 0; i < strategyReports.length; i++) {
            if (strategyReports[i].campaignId === campaignId) {
                return strategyReports[i].strategyReportId;
            }
        }
        throw new Error(`Unable to find strategy report id given the campaign id: ${campaignId}`);
    }

    useEffect(() => {
        if (proveSelectedCampaignSelector && shouldNavigateToLineItems) {
            navigate(
                `${routing.prove.root.path}/${proveSelectedCampaignSelector.id}/${routing.prove.manage.path}`,
            );
            setShouldNavigateToLineItems(false);
        }
    }, [proveSelectedCampaignSelector, shouldNavigateToLineItems]);

    const createMediaPlanFeature = featureBalances?.find(
        (f) => f.featureCode === FeatureCode.MediaPlansCreateMediaPlan,
    );

    const canUserCreateMediaPlans =
        createMediaPlanFeature && createMediaPlanFeature.limit! - createMediaPlanFeature.used! > 0;

    const canUserEnableProve =
        featureBalances?.find((f) => f.featureCode === FeatureCode.ProveSaasAccess) !== undefined;
    const createStrategyReportFeatureBalance = featureBalances?.find(
        (f) => f.featureCode === FeatureCode.StrategyReportCreateReport,
    );
    const canCreateStrategyReport =
        createStrategyReportFeatureBalance &&
        createStrategyReportFeatureBalance.limit! - createStrategyReportFeatureBalance.used! > 0;

    const {
        isOpen: isUpgradePlanModalOpen,
        onOpen: onUpgradePlanModalOpen,
        onClose: onUpgradePlanModalClose,
    } = useDisclosure();

    const {
        isOpen: isEnableProveModalOpen,
        onOpen: onEnableProveModalOpen,
        onClose: onEnableProveModalClose,
    } = useDisclosure();

    const createProveCampaignIfAllowed = async (campaignId: string) => {
        if (!campaignId) {
            return;
        }

        if (canUserEnableProve) {
            setShouldNavigateToLineItems(true);

            dispatch(
                createProveCampaignAsync({
                    configuration: await getProveManagementServiceConfig(),
                    campaignId,
                }),
            );
        } else {
            onEnableProveModalOpen();
        }
    };

    function hasCampaignStarted(globalCampaign: GlobalCampaign) {
        const startDate = moment.utc(globalCampaign.startDate);

        return startDate.isBefore(moment());
    }

    const columns: Array<Column<DataRow>> = [
        {
            title: campaignContent.table.headers.name,
            dataIndex: 'name',
            key: 'name',
            render: (row) => {
                return (
                    <Tooltip label={row.name}>
                        <Text maxWidth={500} isTruncated>
                            {row.name}
                        </Text>
                    </Tooltip>
                );
            },
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: campaignContent.table.headers.startDate,
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => a.startDate.getTime() - b.startDate.getTime(),
            render: (row) => <Box>{DateFormatter.format(row.startDate)}</Box>,
        },
        {
            title: campaignContent.table.headers.endDate,
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: (a, b) => a.endDate.getTime() - b.endDate.getTime(),
            render: (row) => <Box>{DateFormatter.format(row.endDate)}</Box>,
        },
        {
            title: campaignContent.table.headers.owner,
            dataIndex: 'createdBy',
            key: 'owner',
            sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
        },
        {
            title: campaignContent.table.headers.products,
            key: 'products',
            render: (row) => {
                return (
                    <HStack spacing={2}>
                        {(isPlanEnabled(row.key) || hasStrategyReport(row.key)) && (
                            <Badge
                                colorScheme="orange"
                                variant="solid"
                                textTransform="inherit"
                                fontSize="0.75rem"
                                rounded="0.3rem"
                            >
                                {isDraftMediaPlan(row.key) ? 'PLAN (Draft)' : 'PLAN'}
                            </Badge>
                        )}
                        {isProveEnabled(row.key) && (
                            <Badge
                                colorScheme="green"
                                variant="solid"
                                textTransform="inherit"
                                fontSize="0.75rem"
                                rounded="0.3rem"
                            >
                                PROVE
                            </Badge>
                        )}
                    </HStack>
                );
            },
        },
        {
            title: campaignContent.table.headers.action,
            key: 'action',
            render: (row) => {
                return (
                    <HStack spacing={2}>
                        <Menu>
                            <Tooltip isOpen={isCampaignTooltipOpen}>
                                <MenuButton
                                    color="gray.400"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    as={IconButton}
                                    isRound
                                    variant="ghost"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    icon={<EllipsisVerticalIcon />}
                                    size="sm"
                                />
                            </Tooltip>
                            <MenuList minH="48px">
                                {campaignActionItems.map((item) => (
                                    <NavBarMenuListItem
                                        {...item}
                                        href={`${routing.campaign.root.path}/${
                                            row.key
                                        }/${item.path!}`}
                                    />
                                ))}
                                <MenuGroup title="PLAN media plan">
                                    {isPlanEnabled(row.key) ? (
                                        <>
                                            {isDraftMediaPlan(row.key) ? (
                                                <NavBarMenuListItem
                                                    key="create-media-plan"
                                                    children="Create media plan"
                                                    icon={PencilSquareIcon}
                                                    href={`${
                                                        routing.plan.root.path
                                                    }/${resolveMediaPlanId(row.key)}/${
                                                        routing.plan.create.path
                                                    }`}
                                                />
                                            ) : (
                                                <NavBarMenuListItem
                                                    key="view-media-plan"
                                                    children="View media plan"
                                                    icon={PencilSquareIcon}
                                                    href={`${
                                                        routing.plan.root.path
                                                    }/${resolveMediaPlanId(row.key)}`}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <MenuItem
                                            onClick={async () => {
                                                if (canUserCreateMediaPlans) {
                                                    const payload: CreateDraftCampaignRequestDto = {
                                                        organisationId: organisation!.id,
                                                        campaignId: row.key,
                                                        campaignName: row.original.name,
                                                        createdByUserId: user!.id,
                                                        managedByUserId: user!.id,
                                                        campaignStatus: CampaignStatus.Draft,
                                                        toCreateBaselineScenario: true,
                                                    };

                                                    const response = await createDraftCampaign(
                                                        payload,
                                                    );
                                                    navigate(
                                                        `${routing.plan.root.path}/${response.id}/${routing.plan.create.path}`,
                                                    );
                                                } else {
                                                    onUpgradePlanModalOpen();
                                                }
                                            }}
                                            icon={
                                                <Center>
                                                    <Icon as={PencilSquareIcon} boxSize="1.5rem" />
                                                </Center>
                                            }
                                        >
                                            Create media plan
                                        </MenuItem>
                                    )}
                                </MenuGroup>
                                <MenuGroup title="PLAN strategy report">
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {isLoadingStrategyReports ? (
                                        <MenuItem>
                                            <Skeleton w="100%" h="2rem" />
                                        </MenuItem>
                                    ) : hasStrategyReport(row.key) ? (
                                        <NavBarMenuListItem
                                            key="view-strategy-report"
                                            children="View strategy report"
                                            icon={PencilSquareIcon}
                                            href={`${routing.campaign.root.path}/${row.key}/${
                                                routing.strategyReports.root.path
                                            }/${
                                                routing.strategyReports.view.path
                                            }/${resolvedStrategyPlanId(row.key)}`}
                                        />
                                    ) : (
                                        <MenuItem
                                            key="create-strategy-report"
                                            icon={<Icon as={PencilSquareIcon} boxSize="1.5rem" />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                if (canCreateStrategyReport) {
                                                    navigate(
                                                        `/${routing.campaign.root.path}/${row.key}/${routing.strategyReports.root.path}/${routing.strategyReports.create.path}`,
                                                    );
                                                } else {
                                                    onUpgradePlanModalOpen();
                                                }
                                            }}
                                        >
                                            Create strategy report
                                        </MenuItem>
                                    )}
                                </MenuGroup>
                                <MenuGroup title="PROVE">
                                    {isProveEnabled(row.key) ? (
                                        <>
                                            {canManageProveLineItems(row.key) && (
                                                <NavBarMenuListItem
                                                    children="Edit line items"
                                                    icon={PencilSquareIcon}
                                                    href={`${routing.prove.root.path}/${row.key}/${routing.prove.manage.path}`}
                                                />
                                            )}
                                            {hasCampaignStarted(row.original) ? (
                                                <NavBarMenuListItem
                                                    children="View dashboard"
                                                    icon={ChartBarSquareIcon}
                                                    href={`${routing.prove.root.path}/${row.key}/${routing.prove.dashboard.path}`}
                                                />
                                            ) : (
                                                <Tooltip label="Dashboard is disabled until the campaign has started">
                                                    <MenuItem
                                                        children="View dashboard"
                                                        color="grey"
                                                        icon={
                                                            <Center>
                                                                <Icon
                                                                    as={ChartBarSquareIcon}
                                                                    boxSize="1.5rem"
                                                                />
                                                            </Center>
                                                        }
                                                    />
                                                </Tooltip>
                                            )}
                                        </>
                                    ) : (
                                        <MenuItem
                                            children="Enable PROVE"
                                            onClick={() => createProveCampaignIfAllowed(row.key)}
                                            icon={
                                                <Center>
                                                    <Icon as={PencilSquareIcon} boxSize="1.5rem" />
                                                </Center>
                                            }
                                        />
                                    )}
                                </MenuGroup>
                            </MenuList>
                        </Menu>
                    </HStack>
                );
            },
        },
    ];

    const dataSource: Array<Row<DataRow>> = campaigns
        .map((campaign) => ({
            key: campaign.id!,
            name: campaign.name!,
            startDate: new Date(campaign.startDate),
            endDate: new Date(campaign.endDate),
            createdBy: getUserNameFromId(campaign.createdBy),
            original: campaign,
        }))
        // campaigns are sorted by start date in desc order by default
        .sort((a, b) => b.startDate.getTime() - a.startDate.getTime());

    const renderNoCampaignsFound = (): ReactJSXElement => (
        <VStack>
            <Heading variant="amplifiedHeading">{campaignContent.table.noEntries.heading}</Heading>
            <Text fontSize="sm">{campaignContent.table.noEntries.subtitle}</Text>

            <Button
                onClick={() => {
                    navigate(`/${routing.campaign.root.path}/${routing.campaign.create.path}`);
                }}
                variant="outline"
                colorScheme="orange"
            >
                {campaignContent.createButton}
            </Button>
        </VStack>
    );

    return (
        <>
            <DynamicUpgradeModal
                key="DynamicUpgradeModal_CampaignManagementPage"
                isOpen={isUpgradePlanModalOpen}
                onClose={onUpgradePlanModalClose}
                header={<UpgradeMediaPlanLimitHeader />}
            />
            <EnableProveModal
                key="EnableProveModal_GlobalCampaigns"
                isOpen={isEnableProveModalOpen}
                onClose={onEnableProveModalClose}
            />
            <AsyncCard isLoading={proveCampaignIsPendingSelector || !featureBalances}>
                <DataTable
                    isPaginated
                    defaultPageSize={10}
                    dataSource={dataSource}
                    columns={columns}
                    noEntriesComponent={renderNoCampaignsFound()}
                    onRow={(row) => ({
                        _hover: {
                            bgColor: 'gray.50',
                        },
                    })}
                />
            </AsyncCard>
        </>
    );
};
