import { FormatBenchmarkResponseDto } from '@api-clients/attention-data';
import { StrategyReportNavigation } from '@apps/attention360/atoms';
import { SortOption } from '@apps/attention360/atoms/sortOption/SortOption';
import { numberFormatter, SegmentGraph, useParseTabIndex } from '@apps/attention360/pages';
import {
    HELP_SECTION_CONTENT,
    SegmentOptionValue,
} from '@apps/attention360/pages/strategyReports/StrategyReportUtil';
import {
    ActiveAttentionTooltip,
    PassiveAttentionTooltip,
} from '@apps/attention360/pages/strategyReportTooltip';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import { attentionPlanExcelLogo } from '@assets/images';
import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Spacer,
    Stack,
    Switch,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useBoolean,
    VStack,
} from '@chakra-ui/react';
import { AsyncCard } from '@components/atoms';
import { createWorkbook, tableHeaderCellStyle, valueCellStyle } from '@components/Excel';
import { Column, DataTable, Row } from '@components/molecules';
import { routing } from '@configs';
import { useGetAllFormatBenchmarks } from '@hooks/strategyReport/useGetAllFormatBenchmarks';
import { useCustomToast } from '@hooks/toast';
import { buildPng, downloadBlob } from '@shared/utils';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { useSearchParams } from 'react-router-dom';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { ExportButton } from './ExportButton';
import { FormatSelectionGraph } from './FormatSelectionGraph';
import { useStrategyReportContext } from './StrategyReportContextProvider';

const CHANNEL_VIEW_TAB_INDEX_KEY = 'channel_view';

export interface DataRow {
    channelCode: string;
    channelName: string;
    formatName: string;
    formatId: string;
    attentiveReach?: number;
    retentionRate?: number;
    averageActiveAttention: number;
    averagePassiveAttention: number;
    averageInactiveAttention: number;
    activeAttentionPerImpressionCost: number;
    cpm: number;
    isSelected: boolean;
}

let columns: Array<Column<DataRow>> = [
    {
        title: 'Channel',
        dataIndex: 'channelName',
        isNumeric: false,
        key: 'channelId',
        render: (row) => {
            return <>{row.channelName}</>;
        },
    },
    {
        title: 'Format',
        dataIndex: 'formatName',
        isNumeric: false,
        key: 'formatId',
        render: (row) => {
            const baseStyle = {
                display: 'inline-block',
                marginRight: '8px',
            };

            const selectedDotStyle = {
                ...baseStyle,
                width: '8px',
                height: '8px',
                backgroundColor: '#00AC59',
                borderRadius: '50%',
            };

            const selectedTextStyle = {
                color: '#191919',
                fontWeight: 600,
            };

            const unselectedTextStyle = {
                color: '#4A4B4D',
                fontWeight: 500,
            };

            return (
                <>
                    <Flex alignItems="center">
                        {row.isSelected && <Text as="span" style={selectedDotStyle} />}
                        <Text
                            as="span"
                            style={row.isSelected ? selectedTextStyle : unselectedTextStyle}
                        >
                            {row.formatName}
                        </Text>
                    </Flex>
                </>
            );
        },
    },
    {
        title: 'Active attention (s)',
        dataIndex: 'averageActiveAttention',
        isNumeric: true,
        key: 'averageActiveAttention',
        render: (row) => {
            return <>{numberFormatter.format(row.averageActiveAttention)}</>;
        },
        tooltip: <ActiveAttentionTooltip />,
    },
    {
        title: 'Passive attention (s)',
        dataIndex: 'averagePassiveAttention',
        isNumeric: true,
        key: 'averagePassiveAttention',
        render: (row) => {
            return <>{numberFormatter.format(row.averagePassiveAttention)}</>;
        },
        tooltip: <PassiveAttentionTooltip />,
    },
    {
        title: 'Attentive reach',
        dataIndex: 'attentiveReach',
        isNumeric: true,
        key: 'attentiveReach',
        render: (row) => {
            if (row.attentiveReach) {
                const formattedNumber = Math.round(row.attentiveReach * 100);
                return <>{`${formattedNumber}%`}</>;
            }
            return <>N/A</>;
        },
        tooltip: 'The audience percentage that paid at least 1 second of active attention',
    },
    {
        title: 'Retention rate',
        dataIndex: 'retentionRate',
        isNumeric: true,
        key: 'retentionRate',
        render: (row) => {
            if (row.retentionRate) {
                return <>{numberFormatter.format(row.retentionRate)}</>;
            }
            return <>N/A</>;
        },
        tooltip:
            'How well attention retains over the viewing session (0 - 1). 1 means viewers pay the same amount of attention at the end of the ad as the start.',
    },
    {
        title: 'Active attention per impression cost (s)',
        dataIndex: 'activeAttentionPerImpressionCost',
        isNumeric: true,
        key: 'activeAttentionPerImpressionCost',
        render: (row) => {
            return <>{numberFormatter.format(row.activeAttentionPerImpressionCost)}</>;
        },
    },
    {
        title: 'CPM',
        dataIndex: 'cpm',
        isNumeric: true,
        key: 'cpm',
        render: (row) => {
            return <>{numberFormatter.format(row.cpm)}</>;
        },
    },
];

export type FormatSelectionSectionSortOptionValue = 'active' | 'passive' | 'bestValue';

const AllFormatsSortOptions: {
    value: FormatSelectionSectionSortOptionValue;
    heading: string;
    description?: string;
    tooltip?: React.ReactNode;
}[] = [
        {
            value: 'active',
            heading: 'Highest active attention',
            description: 'For all brand sizes and campaign objectives',
            tooltip: <ActiveAttentionTooltip />,
        },
        {
            value: 'passive',
            heading: 'Highest passive attention',
            description: 'For big brands with distinctive assets',
            tooltip: <PassiveAttentionTooltip />,
        },
        {
            value: 'bestValue',
            heading: 'Best value',
            description: 'Highest active attention per impression cost',
        },
    ];

export const FormatSelectionSection: FC = () => {
    const {
        strategyReport,
        showOnlySelectedChannelsOrFormats,
        setShowOnlySelectedChannelsOrFormats,
    } = useStrategyReportContext();
    const { getAllFormatBenchmarks } = useGetAllFormatBenchmarks();
    const [isLoading, setIsLoading] = useState(false);
    const parseChannelViewTabIndex = useParseTabIndex({ min: 0, max: 2 });
    const { errorToast } = useCustomToast();
    const emitViewFormatSelectionEvent = usePosthogEvent(
        'View strategy report format selection section',
    );
    useEffect(() => {
        emitViewFormatSelectionEvent();
    }, []);

    const chartRef = useRef<ChartJSOrUndefined<'bar', string[], object>>();
    const segmentChartRef =
        useRef<
            ChartJSOrUndefined<
                'bubble',
                { x: number; y: number; r: number; label: string; cpm: number }[]
            >
        >();
    const [formatSelectionSortBy, setFormatSelectionSortBy] =
        useState<FormatSelectionSectionSortOptionValue>('active');
    const [formatBenchmarkData, setFormatBenchmarkData] = useState<FormatBenchmarkResponseDto[]>();

    const [selectedSegment, setSelectedSegment] = useState<SegmentOptionValue>('all');
    // State and disclosure hook for the HelpSection
    // Find the heading and content for the selected segment
    const selectedSegmentOption = HELP_SECTION_CONTENT.find(
        (option) => option.value === selectedSegment,
    );

    // Sorting functions for each criterion
    const sortByActiveAttention = (a: DataRow, b: DataRow) => {
        if (a.isSelected !== b.isSelected) return a.isSelected ? -1 : 1;
        return b.averageActiveAttention - a.averageActiveAttention;
    };

    const sortByPassiveAttention = (a: DataRow, b: DataRow) => {
        if (a.isSelected !== b.isSelected) return a.isSelected ? -1 : 1;
        return b.averagePassiveAttention - a.averagePassiveAttention;
    };

    const sortByBestValue = (a: DataRow, b: DataRow) => {
        if (a.isSelected !== b.isSelected) return a.isSelected ? -1 : 1;
        return b.activeAttentionPerImpressionCost - a.activeAttentionPerImpressionCost;
    };

    // Memoized calculation of sorted data
    const displayedFormatBenchmarkData = useMemo(() => {
        if (!formatBenchmarkData) return [];

        // Convert ChannelBenchmarkResponseDto to <Row<DataRow>>
        let dataSource: Array<Row<DataRow>> =
            formatBenchmarkData?.map(
                (f): Row<DataRow> => ({
                    key: f.formatCode ?? '',
                    channelCode: f.adChannelCode ?? '',
                    channelName: f.adChannelName ?? '',
                    formatName: f.formatName ?? '',
                    formatId: f.formatId ?? '',
                    attentiveReach: f.attentiveReachPercentage,
                    retentionRate: f.retentionRate,
                    averageActiveAttention: f.averageActiveAttention ?? 0,
                    averagePassiveAttention: f.averagePassiveAttention ?? 0,
                    averageInactiveAttention: f.averageInactiveAttention ?? 0,
                    activeAttentionPerImpressionCost:
                        f.cpm && f.averageActiveAttention ? f.averageActiveAttention / f.cpm : 0,
                    cpm: f.cpm ?? 0,
                    isSelected: f.isSelected ?? false,
                }),
            ) ?? [];
        // Filter out unselected formats if needed
        if (showOnlySelectedChannelsOrFormats) {
            dataSource = dataSource.filter((row) => row.isSelected);
        }

        // Sort the data based on selected sort option
        switch (formatSelectionSortBy) {
            case 'active':
                dataSource.sort(sortByActiveAttention);
                break;
            case 'passive':
                dataSource.sort(sortByPassiveAttention);
                break;
            case 'bestValue':
                dataSource.sort(sortByBestValue);
                break;
            default:
                break;
        }

        return dataSource;
    }, [formatBenchmarkData, formatSelectionSortBy, showOnlySelectedChannelsOrFormats]);

    const [searchParams, setSearchParams] = useSearchParams();
    const channelViewTabIndex = parseChannelViewTabIndex(
        searchParams.get(CHANNEL_VIEW_TAB_INDEX_KEY),
    );

    useEffect(() => {
        setIsLoading(true);
        getAllFormatBenchmarks({ strategyReportId: strategyReport.strategyReportId! })
            .then((benchmarkData) => {
                setFormatBenchmarkData(benchmarkData);
            })
            .catch(() => {
                errorToast('Failed to load benchmark data', 'Please try again later');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [strategyReport]);
    const onExport = async () => {
        try {
            if (!formatBenchmarkData || channelViewTabIndex < 0 || channelViewTabIndex > 2)
                throw new Error('No Content');
            if (channelViewTabIndex === 0) {
                // Attention segment
                const dataURL = segmentChartRef.current?.toBase64Image('image/png');
                if (!dataURL) throw new Error('No data');
                const blob = await buildPng(dataURL);
                downloadBlob(
                    blob,
                    `attentionPLAN_Format-Selection-Attention-Segments-${strategyReport.name ?? ''
                    }-${new Date().getTime() / 1000}.png`,
                );
                return;
            }
            if (channelViewTabIndex === 1) {
                // benchmark section
                const dataURL = chartRef.current?.toBase64Image('image/png');
                if (!dataURL) throw new Error('No data');
                const blob = await buildPng(dataURL);
                downloadBlob(
                    blob,
                    `attentionPLAN_Format-Selection-Benchmarks-${strategyReport.name ?? ''}-${new Date().getTime() / 1000
                    }.png`,
                );
                return;
            }
            if (channelViewTabIndex === 2) {
                // export to xlsx
                const workbook = createWorkbook();
                const sheet = workbook.addWorksheet('data');
                sheet.properties.defaultColWidth = 32;

                const firstRow = sheet.getRow(1);
                firstRow.height = 60;
                const res = await fetch(attentionPlanExcelLogo);
                const imageBuffer = await res.arrayBuffer();
                const logoId = workbook.addImage({ buffer: imageBuffer, extension: 'png' });
                sheet.addImage(logoId, 'A1:A1');

                const tsCell = sheet.getCell('A3');
                tsCell.value = `Exported at ${new Date().toLocaleString()}`;
                const COLS = 'ABCDEFGH';
                sheet.getCell('A5').value = 'Channel';
                sheet.getCell('B5').value = 'Format';
                sheet.getCell('C5').value = 'Active attention';
                sheet.getCell('D5').value = 'Passive attention';
                sheet.getCell('E5').value = 'Attentive reach';
                sheet.getCell('F5').value = 'Retention rate';
                sheet.getCell('G5').value = 'Active attention per impression cost';
                sheet.getCell('H5').value = 'CPM';
                for (let i = 0; i < COLS.length; i++)
                    sheet.getCell(`${COLS[i]}5`).style = tableHeaderCellStyle;
                const baseRowNumber = 6;
                for (let i = 0; i < displayedFormatBenchmarkData.length; i++) {
                    const row = displayedFormatBenchmarkData[i];
                    const currRowNumber = baseRowNumber + i;
                    sheet.getCell(`A${currRowNumber}`).value = row.channelName ?? '';
                    sheet.getCell(`B${currRowNumber}`).value = row.formatName ?? '';
                    sheet.getCell(`C${currRowNumber}`).value = row.averageActiveAttention ?? 0;
                    sheet.getCell(`D${currRowNumber}`).value = row.averagePassiveAttention ?? 0;
                    sheet.getCell(`E${currRowNumber}`).value = row.attentiveReach ?? 0;
                    sheet.getCell(`F${currRowNumber}`).value = `${(row.retentionRate ?? 0) * 100}`;
                    sheet.getCell(`G${currRowNumber}`).value =
                        row.activeAttentionPerImpressionCost ?? 0;
                    sheet.getCell(`H${currRowNumber}`).value = row.cpm ?? 0;

                    for (let j = 0; j < COLS.length; j++)
                        sheet.getCell(`${COLS[j]}${currRowNumber}`).style = valueCellStyle;
                }
                const buf = await workbook.xlsx.writeBuffer();
                const blob = new Blob([buf], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'",
                });
                downloadBlob(
                    blob,
                    `attentionPLAN-Format-Selection-${strategyReport.name ?? ''}-${new Date().getTime() / 1000
                    }.xlsx`,
                );
                return;
            }
        } catch (e: any) {
            errorToast('Export failed', 'Something went wrong');
        }
    };

    return (
        <Flex flexDir="column" gap="1rem" alignItems="stretch">
            <Stack direction="row" justifyContent="space-between" w="full">
                <ContainerTitle
                    headingText="Format selection"
                    subtitleText="We have recommended and ranked media formats performance based on various attention metrics for your campaign, tailored to your targeted demographics"
                />
                <Spacer marginRight="10rem" />
                <VStack align="end">
                    <HStack>
                        <ExportButton onClick={() => onExport()} />
                    </HStack>
                    <Box>
                        <FormControl display="flex" alignItems="center">
                            <FormLabel mb="0">Show only selected formats</FormLabel>
                            <Switch
                                isChecked={showOnlySelectedChannelsOrFormats}
                                onChange={(e) =>
                                    setShowOnlySelectedChannelsOrFormats(e.target.checked)
                                }
                            />
                        </FormControl>
                    </Box>
                </VStack>
            </Stack>
            <Tabs
                colorScheme="orange"
                index={channelViewTabIndex}
                onChange={(newIndex) => {
                    setSearchParams((params) => {
                        params.set(CHANNEL_VIEW_TAB_INDEX_KEY, newIndex.toString());
                        return params;
                    });
                }}
            >
                <Flex justifyContent="space-between" gap="2rem" alignItems="flex-end">
                    <Flex gap=".5rem" alignItems="center">
                        {
                            // attention segment tab
                            channelViewTabIndex !== 0 &&
                            AllFormatsSortOptions.map((option) => {
                                return (
                                    <SortOption
                                        key={option.value}
                                        value={option.value}
                                        heading={option.heading}
                                        description={option.description}
                                        selectedSortOption={formatSelectionSortBy}
                                        setSelectedSortOption={(value) =>
                                            setFormatSelectionSortBy(value)
                                        }
                                        tooltip={option.tooltip}
                                    />
                                );
                            })
                        }
                    </Flex>

                    <TabList>
                        <Tab>Attention segments</Tab>
                        <Tab>Benchmarks</Tab>
                        <Tab>Table view</Tab>
                    </TabList>
                </Flex>
                <TabPanels>
                    <TabPanel px={0}>
                        {/** Attention segments */}
                        <SegmentGraph
                            ref={segmentChartRef}
                            showOnlySelectedFormat={showOnlySelectedChannelsOrFormats}
                        />
                    </TabPanel>
                    <TabPanel px={0}>
                        {/** Benchmarks */}
                        <AsyncCard isLoading={isLoading}>
                            <FormatSelectionGraph
                                ref={chartRef}
                                benchmarks={displayedFormatBenchmarkData}
                            />
                        </AsyncCard>
                    </TabPanel>
                    <TabPanel px={0}>
                        {/** Table view */}
                        <AsyncCard isLoading={isLoading}>
                            <DataTable
                                columns={columns}
                                dataSource={displayedFormatBenchmarkData}
                            />
                        </AsyncCard>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            {!isLoading && (
                <Box>
                    <StrategyReportNavigation
                        prev={`../${routing.strategyReports.view.channelSelection.path}`}
                        next={`../${routing.strategyReports.view.audienceAnalysis.path}`}
                    />
                </Box>
            )}
        </Flex>
    );
};
