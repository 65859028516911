import {Configuration} from "@api-clients/runtime";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";

export class CreateProveCampaignApi {

    static async execute(
        configuration: Configuration,
        campaignId: string
    ): Promise<ProveCampaign> {
        const response = await fetch(`${configuration.basePath}/a360/v1/prove/campaigns/${campaignId}`, {
            method: 'POST',
            headers: configuration.headers,
            // an empty body is required because the create campaign API has an optional partner_id field
            // currently this new field is not managed via self-serve
            body: `{"apiVersion": "V1"}`
        });

        return response.json();
    }

}
