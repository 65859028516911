import React, {FC} from 'react'
import {Container, Spacer} from "@chakra-ui/react";
import {ProveCampaign} from "@api-clients/prove/schema/ProveCampaign";
import {ProveDashboardSearchCriteriaProps} from "@components/organisms/prove/ProveDashboardHeader";
import {GlobalCampaign} from "@api-clients/global/campaign/schema/GlobalCampaign";
import {ProveDashboardOverview} from "@components/organisms/prove/ProveDashboardOverview";
import {ProveDashboardBreakdown} from "@components/organisms/prove/ProveDashboardBreakdown";

interface ProveDashboardBreakdownField {
    id: string;
    v1: string;
    v2: string;
    label: string;
    maxRecords?: number
}

export interface ProveDashboardBreakdownConfig {
    id: string;
    label: string;
    fields: Array<ProveDashboardBreakdownField>;
    chartType: string;
    chartLimit?: number;
}

export interface ProveDashboardBreakdownProps {
    globalCampaign: GlobalCampaign;
    proveCampaign: ProveCampaign;
    searchCriteria: ProveDashboardSearchCriteriaProps;
}

export const ProveDashboardBody: FC<ProveDashboardBreakdownProps> = ({
                                                                         globalCampaign,
                                                                         proveCampaign,
                                                                         searchCriteria
                                                                     }) => {

    return (
        <Container mt={4}>
            <ProveDashboardOverview proveCampaign={proveCampaign} searchCriteria={searchCriteria}/>
            <Spacer height={4}/>
            <ProveDashboardBreakdown globalCampaign={globalCampaign} proveCampaign={proveCampaign}
                                     searchCriteria={searchCriteria}/>
        </Container>
    )

}
