import { Configuration } from '@api-clients/runtime';
import { ProveCampaign } from '@api-clients/prove/schema/ProveCampaign';
import { ProveLineItem } from '@api-clients/prove/schema/ProveLineItem';
import moment from 'moment';
import { ProveReportData } from '@api-clients/prove/schema/ProveDashboard';

export class RetrieveOverviewReportDataV2Api {

    static async execute(
        configuration: Configuration,
        campaign: ProveCampaign,
        lineItems: Array<ProveLineItem>,
        start: Date,
        end: Date,
    ): Promise<ProveReportData> {
        const encodedLineItemPartnerIds = [] as Array<String>;

        for (let i = 0; i < lineItems.length; i++) {
            if (lineItems[i].partnerId !== undefined) {
                encodedLineItemPartnerIds.push(encodeURIComponent(lineItems[i].partnerId!));
            }
        }

        const formattedStart = encodeURIComponent(moment(start).startOf('day').local(true).toISOString(true));
        const formattedEnd = encodeURIComponent(moment(end).endOf('day').local(true).toISOString(true));

        // TODO: update logic so we perform a domain lookup
        const response = await fetch(`https://default.prove-dev.amplifiedintelligence.io/prove/v2/report?filter=metadata.campaignId:${encodeURIComponent(campaign.partnerId!)}&filter=metadata.insertionOrderId:${encodeURIComponent(campaign.insertionOrder!.partnerId!)}&filter=metadata.lineItemId:${encodedLineItemPartnerIds.join("~")}&start=${formattedStart}&end=${formattedEnd}&metrics=${['impressions', 'avgActiveAttention', 'sumActiveAttention', 'avgPassiveAttention', 'sumPassiveAttention', 'avgNonAttention', 'sumNonAttention', 'sumTotalAttention'].join(',')}`, {
            method: 'GET',
            headers: configuration.headers,
        });

        return response.json();
    }

}
